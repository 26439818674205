<template>
  <div class="my_content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="999"></el-tab-pane>
      <el-tab-pane label="待确认" name="0"></el-tab-pane>
      <el-tab-pane label="已确认" name="1"></el-tab-pane>
      <el-tab-pane label="不通过" name="2"></el-tab-pane>
    </el-tabs>
    <div class="empty" v-if="list.length == 0">
      <div>
        <img src="../../assets/empty_box.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <div class="list" v-else>
      <div class="list_item" v-for="item in list" :key="item.id">
        <h2 class="font16 color33 fontw" style="line-height: 28px">
          {{ item.skillCategoryFirstName }}- {{ item.skillCategorySecondName }}-
          {{ item.skillCategoryThirdName }}考试
        </h2>
        <p class="color33">
          <span class="font14 status">计划号</span>
          <span class="point">：</span>
          <span class="font14">{{ item.planNo }}</span>
        </p>
        <p class="color33" v-if="item.planStartTime">
          <span class="font14 status">认定时间</span>
          <span class="point">：</span>
          <span class="font14"
            >{{ item.planStartTime.split(" ")[0].replace(/-/g, ".") }}-{{
              item.planEndTime.split(" ")[0].replace(/-/g, ".")
            }}</span
          >
        </p>
        <p class="color33" v-if="item.examStartTime">
          <span class="font14 status">考试时间</span>
          <span class="point">：</span>
          <span class="font14">{{ item.examStartTime }}</span>
        </p>
        <div
          v-if="
            item.planStatus != 2 &&
            item.planStatus != 3 &&
            item.planStatus != 4 &&
            item.planStatus != 5
          "
        >
          <div v-if="item.isAllowExam == 0">
            <!-- 审核中 -->
            <div v-if="item.checkStatus == 0 && !item.orderNo">
              <div class="right_bg right_bg2"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #ed8c29"> 审核中 </span>
              </p>
              <div class="tips">正在审核，请耐心等待~</div>
            </div>
            <!-- 待缴费 -->
            <div
              v-if="
                item.checkStatus == 0 &&
                item.orderNo &&
                (!item.payStatus || item.payStatus == 1)
              "
            >
              <div class="right_bg right_bg5"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #5586e0"> 待缴费 </span>
              </p>

              <div class="to_Pay">
                <el-dropdown type="primary">
                  <el-button type="primary" class="to_pay_c">
                    去缴费<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>

                  <el-dropdown-menu>
                    <div v-if="showPay">
                      <li
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                        @click="toPay(item, 2)"
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon1.png"
                          alt=""
                        />微信
                      </li>
                      <li
                        @click="toPay(item, 1)"
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon2.png"
                          alt=""
                        />支付宝
                      </li>
                      <li
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                        @click="toRePay(item)"
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon3.png"
                          alt=""
                        />线下
                      </li>
                    </div>
                    <div v-else>
                      <!-- v-if="payConfig.wxpayControl == 1" -->
                      <li
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                        v-if="payConfig.wxpayControl == 1"
                        @click="toPay(item, 2)"
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon1.png"
                          alt=""
                        />微信
                      </li>
                      <!-- v-if="payConfig.alipayControl == 1" -->
                      <li
                        @click="toPay(item, 1)"
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                        v-if="payConfig.alipayControl == 1"
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon2.png"
                          alt=""
                        />支付宝
                      </li>
                      <!-- v-if="payConfig.offlineControl == 1" -->
                      <li
                        style="
                          width: 150px;
                          line-height: 55px;
                          display: flex;
                          padding: 0 10px;
                          cursor: pointer;
                          align-items: center;
                        "
                        v-if="payConfig.offlineControl == 1"
                        @click="toRePay(item)"
                      >
                        <img
                          style="width: 30px; margin: 0 10px"
                          src="../../assets/pay_icon3.png"
                          alt=""
                        />线下
                      </li>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <!-- 审核中 -->
            <div
              v-if="
                item.checkStatus == 0 &&
                item.orderNo &&
                (item.payStatus == 2 ||
                  item.payStatus == 7 ||
                  item.payStatus == 5) &&
                !item.examStartTime
              "
            >
              <div class="right_bg right_bg2"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #ed8c29"> 审核中 </span>
              </p>
              <div class="tips">正在审核，请耐心等待~</div>
            </div>
            <!-- 待编排 -->
            <div
              v-if="
                item.checkStatus == 0 &&
                item.payStatus == 4 &&
                !item.examStartTime
              "
            >
              <div class="right_bg right_bg1"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #5586e0"> 待编排 </span>
              </p>
              <div
                class="btn"
                @click="downloadCard(item)"
                style="background-color: #34ce98"
              >
                下载准考证
              </div>
              <div
                class="btn btn2"
                v-if="item.examinationNotesFileUrl"
                @click="DownLoadNotes(item)"
              >
                下载考前须知
              </div>
            </div>
            <!-- 审核不通过 -->
            <div v-if="item.checkStatus == 2">
              <div class="right_bg right_bg3"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #ff5b5b">
                  审核不通过

                  <el-popover
                    placement="bottom"
                    title=""
                    width="200"
                    :content="item.checkRemark"
                    trigger="click"
                  >
                    <i class="el-icon-question" slot="reference"></i>
                  </el-popover>
                </span>
                <!-- <span class="font14" style="color: #ff5b5b">
                审核不通过<el-tooltip
                  :content="item.checkRemark"
                  placement="top"
                >
                </el-tooltip>
              </span> -->
              </p>
              <div class="btn" @click="toFinishInfo(item)">完善资料</div>
            </div>

            <!-- 审核不通过 -->
            <!-- <div v-if="item.checkStatus == 2">
            <p class="color33">
              <span class="font14 status">
                状 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态 ：
              </span>
              <span class="font14" style="color: #ff5b5b"> 审核不通过 </span>
            </p>
            <div class="btn">完善资料</div>
          </div> -->
          </div>
          <!-- 不允许考试 -->
          <div v-else-if="item.isAllowExam == 1">
            <div class="right_bg right_bg1"></div>
            <!-- 待编排 -->
            <div v-if="!item.examStartTime">
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <span class="font14" style="color: #5586e0"> 审核通过,待编排 </span>
              </p>
              <div class="tips">等待相关人员进行编排~</div>
            </div>
            <div v-else>
              <div class="right_bg right_bg4"></div>
              <p class="color33">
                <span class="font14 status"> 状态 </span>
                <span class="point">：</span>
                <!-- 审核通过 -->
                <span class="font14" style="color: #5586e0"> 已编排 </span>
              </p>
              <div
                class="btn"
                @click="downloadCard(item)"
                style="background-color: #34ce98"
              >
                下载准考证
              </div>
              <div
                class="btn btn2"
                v-if="item.examinationNotesFileUrl"
                @click="DownLoadNotes(item)"
              >
                下载考前须知
              </div>
            </div>
          </div>
          <div v-else>
            <div class="right_bg right_bg3"></div>
            <p class="color33">
              <span class="font14 status"> 状态 </span>
              <span class="point">：</span>
              <span class="font14" style="color: #ff5b5b"> 不允许考试 </span>
            </p>
          </div>
        </div>
        <div v-else>
          <div class="right_bg right_bg1"></div>
          <div>
            <p class="color33">
              <span class="font14 status"> 状态 </span>
              <span class="point">：</span>
              <span class="font14" style="color: orange">
                {{
                  item.planStatus == 2
                    ? "已考试"
                    : item.planStatus == 3
                    ? "已考试"
                    : item.planStatus == 4
                    ? "已过期"
                    : ""
                }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="情况说明"
      :visible.sync="showTips"
      width="620px"
      :before-close="handleClose"
    >
      <div class="content">
        <span>情况说明情况说明情况说明情况说明情况说明</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <span @click="showTips = false">确 定</span>
      </div>
    </el-dialog>
    <el-dialog
      title="报名缴费"
      :visible.sync="showWxPay"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="img">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
      <p class="tip">打开微信 '扫一扫' 报名缴费</p>
    </el-dialog>

    <el-dialog
      title="重新支付"
      :visible.sync="showReopay"
      width="60%"
      :close-on-click-modal="false"
    >
      <div class="upload_box">
        <div class="pay_user" style="width: 70%; margin: 0 auto">
          <div class="pay_info">
            <p class="title">
              请参与人员<i>5个工作日内</i>将认定费用汇款至以下账户：
            </p>
            <div class="pay_info_box">
              <p>账户名称：{{ companyData.accoutName }}</p>
              <p>开户银行：{{ companyData.bankName }}</p>
              <p>银行账号：{{ companyData.bankNumber }}</p>
            </div>
            <p class="bottom_title">
              汇款时请在汇款用途注明：姓名+操作员+级别+认定费
            </p>
            <p class="bottom_title_text">（例：<i>张三操作员四级认定费</i>）</p>
          </div>
        </div>
        <div class="title_box">
          <span class="title_left">支付凭证</span>
        </div>
        <div class="form_box">
          <span>交易订单截图</span>
          <el-upload
            class="upload-demo"
            action="#"
            :on-preview="handlePreview"
            :file-list="fileList"
            list-type="picture"
            :auto-upload="false"
            :on-error="changePicError"
            :on-change="changeViewOrder"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/jepg文件，且不超过500kb
            </div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toCommitPay">确 定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showOrder"
      :on-close="
        () => {
          showOrder = false;
        }
      "
      :url-list="[fileList[0].url]"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import QRCode from "qrcodejs2";
import {mapState, mapMutations} from "vuex";
import {
  queryAchievementList,
  // bannerList,
  prepay,
  getPaymentConfig,
  // applyInvoicing,
  updatePlanStudentStatus,
  getOrderStatus,
  uploadPaymentImage,
  companyInfo,
} from "@/util/api";
import axios from "axios";
export default {
  data() {
    return {
      activeName: "999",
      showTips: false,
      list: [],
      times: 3,
      dialogVisible: false,
      radio: 2, //支付方式
      showCode: false,
      succree_stutas: false,
      orderNo: null,
      payTimer: null,
      payStatus: "",
      showWxPay: false,
      fileList: [],
      payOrderNo: null,
      showOrder: false,
      showControl: false,
      showReopay: false,
      companyData: "",
      payConfig: {},
      isChangeStatus: true,
      itemId: null,
      showPay: false,
    };
  },
  mounted() {
    this.getList();
    this.getCompany();
    this.getPayConfig();
  },
  computed: {
    ...mapState(["showBaseInfo", "isChange"]),
  },
  watch: {
    isChange(val) {
      if (val) {
        console.log(val);
        updatePlanStudentStatus({
          planStudentId: this.itemId,
        }).then(() => {
          this.changeisChange(false);
          this.getList();
        });
      }
    },
  },
  methods: {
    ...mapMutations(["changeShowInfo", "changeisChange"]),
    getPayConfig() {
      getPaymentConfig({domainUrl: window.location.hostname}).then((res) => {
        this.payConfig = res.data;
        if (
          this.payConfig.wxpayControl == 0 &&
          this.payConfig.alipayControl == 0 &&
          this.payConfig.offlineControl == 0
        ) {
          this.showPay = true;
        } else {
          console.log("111");
          this.showPay = false;
        }
      });
    },
    handlePreview(file) {
      console.log(file);
      this.showOrder = true;
    },
    getList() {
      queryAchievementList({
        checkStatus: this.activeName == "999" ? "" : this.activeName,
        domainUrl: window.location.hostname,
      }).then((res) => {
        this.list = res.data;
      });
    },
    getCompany() {
      companyInfo({domainUrl: window.location.hostname}).then((res) => {
        this.companyData = res.data;
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.getList();
    },
    handleClose() {
      this.showTips = false;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    changeViewOrder(res, file) {
      const isLt10M = res.size / 1024 < 500;
      if (["image/jpeg", "image/jpg"].indexOf(res.raw.type) == -1) {
        this.$message.error("请上传正确的图片格式");
        this.fileList = [];
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传图片不能超过500k哦!");
        this.fileList = [];
        return false;
      }

      let that = this;
      const fr = new FileReader();
      fr.readAsDataURL(res.raw);
      fr.onload = function () {
        that.fileList = [{url: fr.result, name: "支付截图"}];
        that.$forceUpdate();
      };
    },

    handlePreview(file) {
      this.showOrder = true;
    },

    downloadCard(item) {
      axios({
        method: "get",
        url: "/plan/fileDownLoad/" + item.id,
        // data: data.data,
        responseType: "blob",
      })
        .then((res) => {
          let type = "application/pdf";
          const link = document.createElement("a");
          let blob = new Blob([res.data], {type});
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "准考证.pdf"; //自定义文件名
          document.body.appendChild(link); //下载创建a
          link.click();
          document.body.removeChild(link); //下载完删除a
        })
        .catch((error) => {
          this.$message.error("下载失败");
        });
    },
    DownLoadNotes(item) {
      const blobUrl = item.examinationNotesFileUrl;
      // 这里的文件名根据实际情况从响应头或者url里获取
      // const blobUrl = res.data.data.fileUrl;
      // 这里的文件名根据实际情况从响应头或者url里获取
      const filename = "考前须知";
      const a = document.createElement("a"); // 创建a标签
      a.setAttribute("download", "考前须知"); // download属性
      a.setAttribute("target", "_blank");
      a.setAttribute("href", blobUrl); // href链接
      a.click(); // 自执行点击事件
      // const filename = "考前须知";
      // const a = document.createElement("a");
      // a.href = blobUrl;
      // a.download = filename;
      // a.click();
      // window.URL.revokeObjectURL(blobUrl);
    },
    creatQrCode(showCode) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeUrl, {
          text: showCode, // 需要转换为二维码的内容
          width: 250,
          height: 250,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    toPay(item, i) {
      if (
        (i == 1 && this.payConfig.alipayControl == 0) ||
        (i == 2 && this.payConfig.wxpayControl == 0)
      ) {
        this.$message.warning("暂不支持该支付方式");
        return;
      }
      prepay({
        orderNo: item.orderNo,
        payType: i == 2 ? "WXPAY" : "ALIPAY",
        tradeType: "NATIVE",
      }).then((res) => {
        if (i == 1) {
          document.querySelector("body").innerHTML = res.data;
          document.forms[0].submit();
        } else {
          this.showWxPay = true;
          this.showCode = res.data;
          if (window.document.getElementsByClassName("qrcode")[0]) {
            window.document.getElementsByClassName("qrcode")[0].innerHTML = "";
          }

          this.creatQrCode(this.showCode);
          this.callBackPay(item.orderNo);
        }
      });
    },
    callBackPay(orderNo) {
      this.payTimer = setInterval(() => {
        getOrderStatus({
          orderNo: orderNo,
        })
          .then((res) => {
            if (res.data == 1) {
              clearInterval(this.payTimer);
              this.showWxPay = false;
              this.getList();
              // } else if (res.data == 1) {
              //   this.showWxPay = false;
              //   this.getList();
              //   clearInterval(this.payTimer);
            }
          })
          .catch(() => {
            clearInterval(this.payTimer);
            this.showWxPay = false;
            this.getList();
          });
      }, 2000);
    },
    handleClose() {
      this.active = 4;
      this.$message.warning("支付失败");
      this.showWxPay = false;
      clearInterval(this.payTimer);
      this.getList();
    },
    toRePay(item) {
      if (this.payConfig.offlineControl == 0) {
        this.$message.warning("暂不支持该支付方式");
        return;
      }
      this.payItem = item;
      this.showReopay = true;
    },
    toCommitPay() {
      if (this.fileList.length == 0) {
        this.$message.warning("订单截图未上传");
      } else {
        uploadPaymentImage({
          paymentImageData: this.fileList[0].url,
          orderNo: this.payItem.orderNo,
        }).then((res) => {
          this.showReopay = false;
          this.getList();
        });
      }
    },
    toFinishInfo(item) {
      if (item.isApplyFull == 1) {
        this.$router.push({
          path: "/reForInfo",
          query: {
            planId: item.planId,
            isChange: true,
            planStudentId: item.id,
          },
        });
      } else {
        this.itemId = item.id;
        this.changeShowInfo(true);
      }
    },
    changePicError(file) {
      console.log("in");
    },
  },
  destroyed() {
    window.sessionStorage.removeItem("itemId");
  },
};
</script>
<style scoped>
.my_content >>> .el-button--primary {
  background-color: #5586e0;
}
</style>
<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 950px;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
.upload_box {
  margin: 0px auto 20px;
  .pay_box {
    width: 50%;
    margin: 0 auto 30px;
    text-align: center;
    p {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 14px;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }
  .title_box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .title_left {
      font-size: 16px;
      font-weight: bold;
    }
    .title_right {
      color: #2b68d9;
      cursor: pointer;
    }
    .title_right:hover {
      text-decoration: underline;
    }
  }
  .form_box {
    width: 70%;
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    span {
      display: inline-block;
      width: 100px;
      line-height: 40px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .pay_info {
    padding-bottom: 40px;
    border-bottom: 1px solid #f2f2f2;
    .title {
      font-size: 16px;
      font-weight: bold;
      i {
        color: red;
        font-style: normal;
        margin: 0 5px;
      }
    }
    div {
      margin: 20px;
      p {
        line-height: 35px;
        font-size: 16px;
      }
    }
    .bottom_title {
      font-size: 16px;
    }
    .bottom_title_text {
      margin-top: 10px;
      i {
        color: red;
        font-style: normal;
        margin: 0 5px;
      }
    }
  }
}

.qrcode {
  width: 250px;
  height: 250px;
}
.img {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  .qrcode {
    width: 250px;
    height: 250px;
    background-color: #42ef42;
  }

  .success_top {
    font-size: 20px;
    color: #42ef42;
    margin: 24px;
    text-align: center;
  }
}
.tip {
  color: #333333;
  font-size: 16;
  margin: 20px;
  text-align: center;
}
.close_pay {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-top: 20px;
}
.my_content {
  padding: 30px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  .list_item {
    width: 245px;
    height: 300px;
    margin: 20px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    .right_bg {
      position: absolute;
      width: 110px;
      height: 110px;
      // border-radius: 50%;
      right: -15px;
      top: -15px;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .right_bg1 {
      background-image: url("../../assets/bg1.png");
    }
    .right_bg2 {
      background-image: url("../../assets/bg2.png");
    }
    .right_bg3 {
      background-image: url("../../assets/bg3.png");
    }
    .right_bg4 {
      background-image: url("../../assets/bg4.png");
    }
    .right_bg5 {
      background-image: url("../../assets/bg5.png");
    }
    p {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .el-icon-question {
        color: #999999;
        margin-left: 10px;
        cursor: pointer;
      }
      span {
        flex: 1;
      }
      .status {
        flex: none;
        // width: 60px;
        // justify-content: space-between;
        // display: flex;
        // text-align: justify;
      }
      .point {
        flex: none;
        // width: 15px;
        text-align: center;
      }
    }
  }
}
.btn {
  width: 138px;
  height: 32px;
  background: #5586e0;
  color: #fff;
  font-size: 14px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  margin-left: -69px;
  left: 50%;
  // margin: 45px auto 0;
}
.btn2 {
  bottom: 60px;
}
.to_Pay {
  width: 138px;
  height: 32px;
  background: #5586e0;
  color: #fff;
  font-size: 14px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  margin-left: -69px;
  left: 50%;
}
.tips {
  font-size: 14px;
  color: #999999;
  text-align: center;
  margin: 45px auto 0;
}
.dialog-footer {
  text-align: center;
  border-top: 1px solid #cccccc;
  padding-top: 15px;
  color: #5586e0;
  cursor: pointer;
}
.content {
  min-height: 250px;
}
</style>
<style scoped>
.my_content >>> .el-tabs__item {
  color: #999;
}
.my_content >>> .el-tabs__item.is-active {
  color: #5586e0;
}
.my_content >>> .el-tabs__active-bar {
  background-color: #5586e0;
  color: #fff;
}
.my_content >>> .to_pay_c,
.my_content >>> .el-dropdown {
  width: 100%;
  height: 32px;
  line-height: 30px;
  padding: 0;
  border-radius: 16px;
}
</style>
